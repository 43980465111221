.filter-holder {
  width: 100%;
  background: white;
  box-shadow: -3px 4px 4px #dfdfdf;

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 7px 3px;
    border-top-width: 6px;
  }

  .mat-form-field-prefix .mat-icon {
    color: #b9b3b3;
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.5em;
  }

  .mat-form-field {
    border-radius: 8px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  input {
    font-size: 13px;
  }

  .filter-text {
    height: 55px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;

    .hld {
      display: flex;
      align-items: center;

      img {
        width: 20px;
      }

      span {
        transform: scale(0.8);
        margin-top: 4px;
      }
    }
  }

  .vscroll {
    display: inline-flex;
    overflow-x: scroll;
    width: calc(100vw - 90px);
    padding: 5px 0 5px 0;
  }

  .form-item {
    margin-right: 10px;

    .close-btn {
      width: 1em !important;
      height: 1em !important;

      .mat-icon {
        transform: scale(0.6);
      }
    }
  }

  ::-webkit-scrollbar {
    height: 5px !important;
    max-width: 100rem !important;
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar-thumb {
    background: #acaaaa !important;
  }

  .mat-chip-list-wrapper {
    display: flex;
    align-items: center;
    margin: -4px;
    overflow: scroll;
    flex-direction: inherit !important;
    flex-wrap: nowrap !important;
  }

  .mat-chip {
    font-size: 9px;
    width: 100% !important;
    min-width: max-content;
    padding: 7px !important;
    min-height: 27px !important;
    margin: 0 !important;
    margin-right: 5px !important;
  }

  .mat-standard-chip {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 8px 7px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    min-height: 25px;
    height: 1px;
  }

  .tag-input {
    margin-top: 5px;
  }

  .filter-input {
    line-height: 1.6 !important;
    &::placeholder {
      position: absolute !important;
    }
  }

  .filter-dropdown {
    .mat-input-element {
      margin-top: 4px;
    }
    .mat-form-field-suffix {
      top: 0.25em;
    }
  }

  .text-input {
    border-color: #00000052;
    min-width: 13rem;
    border-radius: 8px;
    height: 40px;
    &:focus {
      border: 2px solid #1a85d6 !important;
      box-shadow: none !important;
    }
    &::placeholder {
      opacity: 0.7;
    }
  }
}
