/*-------------Common styles------------------*/

/*---------- root values can be used throughout application---------------------*/

* {
  /*---pixel values---*/
  --0px: 0px;
  --1px: 1px;
  --2px: 2px;
  --3px: 3px;
  --4px: 4px;
  --5px: 5px;
  --6px: 6px;
  --7px: 7px;
  --8px: 8px;
  --9px: 9px;
  --10px: 10px;
  --11px: 11px;
  --12px: 12px;
  --13px: 13px;
  --14px: 14px;
  --15px: 15px;
  --16px: 16px;
  --17px: 17px;
  --18px: 18px;
  --19px: 19px;
  --20px: 20px;
  --21px: 21px;
  --22px: 22px;
  --23px: 23px;
  --24px: 24px;
  --25px: 25px;

  /*---rem values---*/
  --0rem: 0rem;
  --1rem: 1rem;
  --2rem: 2rem;
  --3rem: 3rem;
  --4rem: 4rem;
  --5rem: 5rem;
  --6rem: 6rem;
  --7rem: 7rem;
  --8rem: 8rem;
  --9rem: 9rem;
  --10rem: 10rem;
  --11rem: 11rem;
  --12rem: 12rem;
  --13rem: 13rem;
  --14rem: 14rem;
  --15rem: 15rem;
  --16rem: 16rem;
  --17rem: 17rem;
  --18rem: 18rem;
  --19rem: 19rem;
  --20rem: 20rem;
  --21rem: 21rem;
  --22rem: 22rem;
  --23rem: 23rem;
  --24rem: 24rem;
  --25rem: 25rem;
}
